import { render } from "./Shop.vue?vue&type=template&id=581a5aec&scoped=true"
import script from "./Shop.vue?vue&type=script&lang=js"
export * from "./Shop.vue?vue&type=script&lang=js"

import "./Shop.vue?vue&type=style&index=0&id=581a5aec&lang=sass&scoped=true"
script.render = render
script.__scopeId = "data-v-581a5aec"

export default script
import QImg from 'quasar/src/components/img/QImg.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QCardActions from 'quasar/src/components/card/QCardActions.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QImg,QBtn,QAvatar,QIcon,QBtnDropdown,QList,QItem,QItemSection,QItemLabel,QCard,QCardSection,QCheckbox,QDialog,QInput,QCardActions});qInstall(script, 'directives', {ClosePopup});
